import { ref, watch, computed } from '@vue/composition-api'
// import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'

export default function usePayoutsList() {
  // Use toast
  const toast = useToast()

  const refPayoutListTable = ref(null)
  // Table Handlers
  const tableColumns = [
    {
      key: 'id',
    },
    {
      key: 'name',
      label : 'Recipient'
    },
    {
      key: 'amount',
    },
    {
      key: 'mpesa_transaction_id',
      label: 'Mpesa Code'
    },
    // {
    //   key: 'transaction_id',
    // },
    {
      key: 'created_at',
      label: 'Inititated On'
    },
    {
      key: 'details',
      label: 'Payout Details'
    },
  ]
  const perPage = ref(10)
  const totalPayouts = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const statusFilter = ref(null)
  const isBusy = ref(false)

  const dataMeta = computed(() => {
    const localItemsCount = refPayoutListTable.value ? refPayoutListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalPayouts.value,
    }
  })

  const refetchData = () => {
    refPayoutListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, statusFilter], () => {
    refetchData()
  })

  const fetchPayouts = (ctx, callback) => {
    isBusy.value = true
    store
      .dispatch('payments/fetchPayouts', {
        search: searchQuery.value,
        page_size: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        status: statusFilter.value,
      })
      .then(response => {
        const { data, paginator } = response.data

        callback(data)
        isBusy.value = false
        totalPayouts.value = paginator.length
      })
      .catch(() => {
        isBusy.value = false
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching payouts' list",
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolvePayoutStatusVariantAndIcon = status => {
    if (status === 'pending') return { variant: 'secondary', icon: 'PieChartIcon' }
    if (status === 'queued') return { variant: 'warning', icon: 'PieChartIcon' }
    if (status === 'Disbursed') return { variant: 'success', icon: 'CheckCircleIcon' }
    if (status === 'failed') return { variant: 'danger', icon: 'XIcon' }
    return { variant: 'secondary', icon: 'XIcon' }
  }
  const resolveClientAvatarVariant = status => {
    return 'primary'
  }

  return {
    fetchPayouts,
    tableColumns,
    perPage,
    currentPage,
    totalPayouts,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refPayoutListTable,

    statusFilter,

    resolvePayoutStatusVariantAndIcon,
    resolveClientAvatarVariant,
    refetchData,
  }
}

<template>
<div>
    <b-card
    class="mb-50"
    no-body
  >
    <div class="m-1">

      <!-- Table Top -->
      <b-row>

        <!-- Per Page -->
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <label>Entries</label>
          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block ml-50 mr-1"
          />
        </b-col>

        <!-- Search -->
        <b-col
          cols="12"
          md="6"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              placeholder="Search..."
            />
            <v-select
              v-model="statusFilter"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="statusOptions"
              class="payout-filter-select"
              placeholder="Select Status"
            >
              <template #selected-option="{ label }">
                <span class="text-truncate overflow-hidden">
                  {{ label }}
                </span>
              </template>
            </v-select>
          </div>
        </b-col>
      </b-row>
    </div>
    </b-card>

  <b-card
    no-body
  >
    <b-table
      ref="refPayoutListTable"
      :items="fetchPayouts"
      responsive
      :fields="tableColumns"
      primary-key="id"
      :sort-by.sync="sortBy"
      :busy.sync="isBusy"
      show-empty
      empty-text="No matching records found"
      :sort-desc.sync="isSortDirDesc"
      class="position-relative"
    >
    <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner
          label="Loading..."
          class="align-middle">
          </b-spinner>
          <strong class=" ml-2">Fetching...</strong>
        </div>
      </template>
      <!-- Column: Id -->
      <template #cell(id)="data">
        <span class="text-nowrap">
         <b-link>
         # {{ data.item.id }}
         </b-link>
        </span>
      </template>
      <!-- Column: Client -->
      <template #cell(name)="data">
        <b-media vertical-align="center">
          <template #aside>
            <b-avatar
              size="32"
              :text="avatarText(data.item.name)"
              :variant="`light-${resolveClientAvatarVariant(data.item.details)}`"
            />
          </template>
          <span class="font-weight-bold d-block text-nowrap">
            {{ data.item.name }}
          </span>
          <small class="text-muted">{{ data.item.msisdn }}</small>
        </b-media>
      </template>

      <!-- Column: Payout Status -->
      <template #cell(details)="data">
        <b-avatar
          :id="`payout-row-${data.item.id}`"
          size="32"
          :variant="`light-${resolvePayoutStatusVariantAndIcon(data.item.details).variant}`"
        >
          <feather-icon
            :icon="resolvePayoutStatusVariantAndIcon(data.item.details).icon"
          />
        </b-avatar>
        <b-tooltip
          :target="`payout-row-${data.item.id}`"
          placement="top"
        >
          <p class="mb-0">
            Status: {{ data.item.details }}
          </p>
          <p class="mb-0">
            Amount: {{ data.item.amount }}
          </p>
          <p class="mb-0">
            Transaction Time: {{ data.item.created_at }}
          </p>
        </b-tooltip>
        <span
          v-if="data.item.details =='queued'"
        class="ml-4"
        >
        <b-link
        @click="onRequeuePayout(data.item.msisdn, data.item.amount, data.item.id)"
        >
        
        <feather-icon
          icon="RefreshCwIcon"
          size = "18"
          class="text-warning"
          />
        </b-link>

        </span>
      </template>
      <!-- Column: Balance -->
      <template #cell(amount)="data">
        <span class="text-nowrap">
          {{ Number(data.item.amount).toLocaleString() }}
              <sup>KES</sup>
        </span>
      </template>
      
    </b-table>
    <div class="mx-2 mb-2">
      <b-row>

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="totalPayouts"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>
  </b-card>
</div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BTable, BAvatar, BLink,
  BPagination, BTooltip, BButton, BBadge,BSpinner, BMedia,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { ref, onUnmounted } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import axios from '@axios'

import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import usePayoutsList from './usePayoutList'

import paymentsStoreModule from '../paymentsStoreModule'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BMedia,
    BAvatar,
    BBadge,
    BPagination,
    BTooltip,
    BButton,
    BSpinner,
    BLink,
    vSelect,
  },
  directives: {
    Ripple,
  },
  setup({}, ctx) {
    const PAYMENT_APP_STORE_MODULE_NAME = 'payments'

    // Register module
    if (!store.hasModule(PAYMENT_APP_STORE_MODULE_NAME)) store.registerModule(PAYMENT_APP_STORE_MODULE_NAME, paymentsStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PAYMENT_APP_STORE_MODULE_NAME)) store.unregisterModule(PAYMENT_APP_STORE_MODULE_NAME)
    })
    // Use toast
    const toast = useToast()

    const statusOptions = [
      { label: 'Pending', value: 0 },
      { label: 'Processed', value: 1 },
      { label: 'Failed', value: 2 },
    ]
    const isRequeuePayoutConfirmed = ref(false)
    const reQueuePayoutResponse = ref(null)
    const msg = ref('')
    const okVar = ref('')
    const payoutDetails = ref('')
    const onRequeuePayout = (msisdn,amount,payoutId) => {
      msg.value = 'Requeue Disbursement'
      okVar.value = 'success'
      payoutDetails.value = `${ msisdn } Amount ${ amount }  `
      ctx.root.$bvModal.msgBoxConfirm(`Please confirm that you want to ${msg.value} for ${payoutDetails.value}.\n Money will be disbursed and This action cannot be undone!`, {
          title: `${ msg.value }`,
          size: 'sm',
          buttonSize: 'sm',
          okVariant: okVar.value,
          okTitle: 'YES',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true
        })
          .then(value => {
            isRequeuePayoutConfirmed.value = value
            if(isRequeuePayoutConfirmed.value==true){
              axios.get(`/payments/payouts/${payoutId}/requeue/`)
                .then(response => {
                  reQueuePayoutResponse.value = JSON.parse(JSON.stringify(response.data.data))
                  refetchData()
                  toast({
                    component: ToastificationContent,
                    props: {
                      title: reQueuePayoutResponse.value.message,
                      icon: 'AlertTriangleIcon',
                      variant: 'success',
                    },
                  })
                })
            }
          })
          .catch(err => {
            // An error occurred
          })
      }

    const {
      fetchPayouts,
      tableColumns,
      perPage,
      currentPage,
      totalPayouts,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refPayoutListTable,

      statusFilter,

      refetchData,
      isBusy,
      resolvePayoutStatusVariantAndIcon,
      resolveClientAvatarVariant,
    } = usePayoutsList()

    return {
      fetchPayouts,
      tableColumns,
      perPage,
      currentPage,
      totalPayouts,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refPayoutListTable,

      statusFilter,

      refetchData,
      isBusy,

      statusOptions,
      avatarText,
      resolvePayoutStatusVariantAndIcon,
      resolveClientAvatarVariant,
      onRequeuePayout,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
/* Busy table styling */
table.b-table[aria-busy='true'] {
  opacity: 0.6;
}

.payout-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
